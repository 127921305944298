<template>
  <div>
    <b-card
      no-body
      class="card-employee-task"
      v-if="isGettingIndicents == false"
    >
      <b-card-header>
        <b-card-title>....</b-card-title>
        <b-button
          size="sm"
          :to="{ name: 'operations' }"
          variant="warning"
          v-b-tooltip.hover.top="'Regresar a operaciones'"
        >
          <b-icon-arrow-left></b-icon-arrow-left>
        </b-button>
      </b-card-header>
      <!-- body -->
      <b-card-body>
        <div class="text-center">
          <b-spinner label="Loading..." variant="success" /><br />
          <strong>Buscando información</strong>
        </div>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="card-employee-task"
      v-if="orderDetail && isGettingIndicents == true"
    >
      <b-card-header>
        <b-card-title> Incidencias</b-card-title>
        <b-button
          size="sm"
          :to="{ name: 'operations' }"
          variant="warning"
          v-b-tooltip.hover.top="'Regresar a operaciones'"
        >
          <b-icon-arrow-left></b-icon-arrow-left>
        </b-button>
      </b-card-header>
      <!-- body -->
      <b-card-body>
        <b-row>
          <b-col>
            <b-table bordered responsive="sm" :items="dataCustomer" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <ValidationObserver ref="incidencias" v-slot="{ invalid }">
              <b-row>
                <b-col>
                  <span>Título incidencia</span>
                  <ValidationProvider rules="required" name="Titulo">
                    <b-form-group slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="incident.titleIncdents"
                        :options="title"
                      >
                      </b-form-select>
                      <b-form-invalid-feedback
                        >{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col>
                  <span>No. POS</span>
                  <ValidationProvider rules="" name="No. POS">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-input
                        v-model="orderDetail.order"
                        placeholder="No. POS"
                        :disabled="true"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span>Tipo de Queja</span>
                  <ValidationProvider rules="required" name="Categoría">
                    <b-form-group slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="incident.categoryId"
                        :options="incType"
                      >
                      </b-form-select>
                      <b-form-invalid-feedback
                        >{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <span>Referido </span>
                  <ValidationProvider name="Informador">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-input
                        type="text"
                        v-model="incident.createdby"
                        placeholder="Referido"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <span>Quién atendió </span>
                  <ValidationProvider rules="required" name="Asignada a">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-select
                        v-model="incident.createUser"
                        :state="errors[0] ? false : valid ? true : null"
                        :options="usersChannel"
                        :disabled="true"
                      >
                      </b-form-select>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="6 ">
                  <span>Asignada a</span>
                  <ValidationProvider rules="required" name="Asignada a">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-select
                        v-model="incident.assignedIncident"
                        :state="errors[0] ? false : valid ? true : null"
                        :options="users"
                      >
                      </b-form-select>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="6 mt-1">
                  <span>Estatus de queja </span>
                  <ValidationProvider name="Estatus de queja ">
                    <b-input-group slot-scope="{ errors }">
                      <b-form-select
                        v-model="incident.statusIncident"
                        :disabled="true"
                        :options="status"
                      >
                      </b-form-select>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <span>Descipcion de Queja</span>
                  <ValidationProvider rules="max:1500" name="Resumen">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label="Nota Interna( max: 1500)"
                    >
                      <b-input-group>
                        <b-form-textarea
                          v-model="incident.summary"
                          rows="2"
                          max-rows="3"
                          :state="errors[0] ? false : valid ? true : null"
                        >
                        </b-form-textarea>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                      <span class="text-muted"
                        >Carácteres restantes
                        {{ maxTextTarea - incident.summary.length }}
                      </span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <span>Tipo compensación</span>
                  <ValidationProvider rules="required" name="Descripcion">
                    <b-form-group slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="incident.resoluc"
                        :options="descRes"
                      >
                      </b-form-select>
                      <b-form-invalid-feedback
                        >{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="12">
                  <ValidationProvider name="compensation">
                    <b-form-group
                      slot-scope="{ errors }"
                      label="Costo de compensación"
                    >
                      <b-form-input
                        type="number"
                        step=".01"
                        v-model="incident.compensation"
                        placeholder="Ingrese la Compensación"
                        maxlength="10"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="12">
                  <ValidationProvider name="Evidencias">
                    <b-form-group
                      label="Evidencias"
                      slot-scope="{ valid, errors }"
                    >
                      <b-form-file
                        v-model="file"
                        @input="setImgFile(file)"
                        placeholder="Adjunte una imagen como evidencia..."
                        drop-placeholder="Drop file here..."
                        :state="errors[0] ? false : valid ? true : null"
                        browse-text="Buscar imagen"
                      />
                    </b-form-group>
                  </ValidationProvider>

                  <b-card-text class="my-1">
                    <table
                      v-if="incident.file.name != '' && incident.file != ''"
                      class="table table-bordered table-sm mb-1"
                    >
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Nombre</th>
                          <th scope="col">Borrar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              :href="attrLink + '' + incident.file.name"
                              download
                              target="_blank"
                              >{{
                                incident.file.nameShort
                                  ? incident.file.nameShort
                                  : incident.file.name
                              }}</a
                            >
                          </td>
                          <td>
                            <a
                              :href="attrLink + '/' + incident.file.name"
                              download
                              target="_blank"
                            >
                              <b-button
                                size="sm"
                                variant="success"
                                class="mr-1"
                              >
                                <span class="pdfIconLoad"> 📄 </span>
                              </b-button>
                            </a>

                            <b-button
                              size="sm"
                              variant="danger"
                              @click="deleteFileIncident()"
                            >
                              <b-icon icon="trash-fill"></b-icon>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <div v-if="incident.id != ''" class="float-left">
                    <a
                      :href="
                        attrLink +
                        '/Transactions/indicentensreport/' +
                        incident.id +
                        '/'
                      "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b-button variant="primary" :disabled="invalid">
                        <b-icon-file-earmark-ruled-fill></b-icon-file-earmark-ruled-fill>
                        Generear PDF
                      </b-button>
                    </a>
                  </div>
                  <div v-if="incident.id == ''" class="float-right">
                    <b-button
                      variant="primary"
                      :disabled="invalid"
                      @click="sendIncident(incident, orderDetail.id)"
                    >
                      <b-icon-check-square-fill></b-icon-check-square-fill>
                      Guardar
                    </b-button>
                  </div>

                  <div v-if="incident.statusIncident == 2" class="float-right">
                    <b-button
                      class="ml-2"
                      variant="danger"
                      @click="updateClose(incident, incident.id)"
                    >
                      <span class="pdfIcon"> 🚫 </span>
                      Cerrar incidencia
                    </b-button>
                  </div>
                  <div
                    v-if="incident.statusIncident == 3 && enableButton == false"
                    class="float-right"
                  >
                    <b-button
                      variant="success"
                      :disabled="invalid"
                      @click="update(incident, incident.id)"
                    >
                      <b-icon-check-square-fill></b-icon-check-square-fill>
                      Reabrir incidencia
                    </b-button>
                  </div>
                  <div class="float-right">
                    <b-button
                      variant="primary"
                      :disabled="invalid"
                      @click="update(incident, incident.id)"
                    >
                      <b-icon-check-square-fill></b-icon-check-square-fill>
                      Actualizar
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </ValidationObserver>
          </b-col>
          <b-col md="6">
            <h3 class="text-center mt-1">Seguimiento de incidencias</h3>

            <ValidationObserver ref="incidencias">
              <div class="description">
                <div
                  v-for="(items, index) in summary"
                  :key="index"
                  class="message-box-holder"
                >
                  <div :class="items.class">
                    <span class="titleDescription">
                      {{ items.username }} {{ items.createdate }}
                    </span>

                    <div class="mb-1">{{ items.summary }}</div>
                    <span
                      v-if="items.files.name != ''"
                      class="titleDescriptionFooter align-items-left"
                    >
                      <a
                        :href="attrLink + '/' + items.files.name"
                        download
                        target="_blank"
                      >
                        <span class="pdfIcon"> 📄 </span>
                      </a>
                    </span>
                    <span class="titleDescriptionFooter align-items-right">
                      {{ items.time }}
                    </span>
                  </div>
                </div>
              </div>
              <b-row class="mt-1">
                <b-col>
                  <span>Comentarios</span>
                  <ValidationProvider rules="max:255" name="comments">
                    <b-form-group slot-scope="{ valid, errors }">
                      <b-input-group>
                        <b-form-textarea
                          v-model="comments"
                          rows="2"
                          max-rows="3"
                          :state="errors[0] ? false : valid ? true : null"
                        >
                        </b-form-textarea>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="12">
                  <ValidationProvider name="CommentFile">
                    <b-form-group
                      label="Evidencias"
                      slot-scope="{ valid, errors }"
                    >
                      <b-form-file
                        v-model="commentsFile"
                        @input="setCommentFile(commentsFile)"
                        placeholder="Adjunte una imagen como evidencia..."
                        drop-placeholder="Drop file here..."
                        :state="errors[0] ? false : valid ? true : null"
                        browse-text="Buscar imagen"
                      />
                    </b-form-group>
                  </ValidationProvider>

                  <b-card-text class="my-1">
                    <table
                      v-if="commentsFile != null"
                      class="table table-bordered table-sm mb-1"
                    >
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Nombre</th>
                          <th scope="col">Borrar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ commentsFile.name }}</td>
                          <td>
                            <b-button
                              size="sm"
                              variant="danger"
                              @click="deleteFileComment()"
                            >
                              <b-icon icon="trash-fill"></b-icon>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <div class="float-right">
                    <b-button
                      variant="primary"
                      @click="createSummary(orderDetail.id)"
                      :disabled="incident.id == ''"
                    >
                      <b-icon-check-square-fill></b-icon-check-square-fill>
                      Guardar
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { showAlertMessage } from "@/helpers/helpers";

export default {
  created() {
   
    this.getInitialContentIncidents();
    this.setOrderFromUrl(this.$route.params.order);
  },
  async mounted() {
    await this.fetchUsersByChannel(this.user.canalventa.id);
    if (this.payloadToGetDetailOrder) {
      const orderDetail = await this.getOrderDetailByOrder(
        this.payloadToGetDetailOrder
      );
      this.setOrderDetail(orderDetail);
      if (this.orderDetail) {
        await this.loadInfo();
      }
      this.incident.createUser = this.user.idUser;
    }
  },
  computed: {
    ...mapState("operation", [
      "orderDetail",
      "suppliers",
      "operators",
      "orderDetail",
    ]),
    ...mapState("auth", ["user", ]),
    ...mapState("mySales", ["usersChannel"]),
    ...mapState("incidents", ["incident", "status", "summary", ""]),
    ...mapState("incidents", ["users", "title", "incType", "descRes"]),
    dataCustomer() {
      return [
        {
          Orden: this.orderDetail.order,
          Nombre: this.orderDetail.customer.name,
          Email: this.orderDetail.customer.email,
          Teléfono: this.orderDetail.customer.phone,
          Forma_De_Pago: this.orderDetail.paymentForm,
          Hotel: this.orderDetail.hotel,
          Referencia: this.orderDetail.reference,
        },
      ];
    },
    enableButton() {      
      return false
    },
  },
  data() {
    return {
      payloadToGetDetailOrder: null,
      file: null,
      fileList: [],
      commentsFile: null,
      comments: "",
      attrLink: process.env.VUE_APP_IMG_SRC_API,
      type: "",
      max: 1500,
      maxTextTarea: 1500,
      isLoadingInfo: false,
      isGettingIndicents: false,
    };
  },
  methods: {
    ...mapActions("operation", [
      "fetchOperatorsBySupplier",
      "fetchSuppliers",
      "updateOrderOperation",
      "updateSupplierOperator",
      "getOrderDetailByOrder",
    ]),
    ...mapActions("incidents", [
      "fetchStatusIncidents",
      "createIncident",
      "fetchIncidents",
      "incidentsdescription",
      "fetchInincIdentsDescription",
      "incidentdesupdate",
      "getInitialContentIncidents",
    ]),

    ...mapMutations("incidents", [
      "deleteFile",
      "addFileIncident",
      "setIncidentClear",
      "setIncidentsdescription",
    ]),

   

    ...mapMutations("incidents", [
      "deleteFile",
      "addFileIncident",
      "setIncidentClear",
      "setIncidentsdescription",
    ]),
    ...mapMutations("operation", [
      "setSupplierOrderOperation",
      "unsetOperators",
      "setOperatorOrderOperation",
      "setOrderDetail",
    ]),
    ...mapActions("mySales", ["fetchUsersByChannel"]),
    setOrderFromUrl(idOrder) {
      const orderIsANumber = !isNaN(idOrder);
      // const idDetailIsANumber = !isNaN(idDetail)

      if (orderIsANumber) {
        this.payloadToGetDetailOrder = { idOrder };
      }
    },
    async setSupplierAndGetOperators(supplier) {
      if (supplier) {
        await this.setSupplierOrderOperation(supplier);
        await this.fetchOperatorsBySupplier(supplier);
      } else {
        this.unsetOperators();
      }
    },
    setOperator(operator) {
      if (operator) {
        this.setOperatorOrderOperation(operator);
      }
    },
    setImgFile(file) {
      if (file) {
        this.addFileIncident(file);
      } else {
        const text = `😪😪 Tiene que adjuntar un archivo de tipo imagen`;
        showAlertMessage(
          "No hay imagen",
          "InfoIcon",
          text,
          "danger",
          4000,
          "bottom-right"
        );
      }
    },
    setCommentFile(commentsFile) {
      if (commentsFile) {
        this.addcommentsFile(commentsFile);
      } else {
        const text = `😪😪 Tiene que adjuntar un archivo de tipo imagen`;
        showAlertMessage(
          "No hay imagen",
          "InfoIcon",
          text,
          "danger",
          4000,
          "bottom-right"
        );
      }
    },
    deleteFileIncident() {
      this.incident.file = "";
      this.file = "";
    },
    deleteFileComment() {
      this.commentsFile = null;
    },
    async sendIncident(incident, id) {
      this.incident.pos = id;
      this.incident.statusIncident = 1;
      await this.createIncident(incident);
      await this.loadInfo();
    },
    async createSummary(id) {
      const payload = {
        id: id,
        userId: this.user.idUser,
        summary: this.comments,
        commentsFile: this.commentsFile,
      };
      this.comments = "";
      await this.incidentsdescription(payload);
      await this.fetchInincIdentsDescription(this.incident.id);
    },
    update(incident, id) {
      this.incident.pos = id;
      if (incident.statusIncident != 3) {
        this.incident.statusIncident = 2;
      }

      this.incident.createUser = this.user.idUser;
      this.incidentdesupdate(incident);
    },
    updateClose(incident, id) {
      this.incident.pos = id;

      this.incident.statusIncident = 3;
      this.incident.createUser = this.user.idUser;
      this.incidentdesupdate(incident);
    },

    async loadInfo() {
      this.isGettingIndicents = false;
      await this.setIncidentClear();
      await this.fetchStatusIncidents();
      await this.fetchIncidents(this.orderDetail.id);
      await this.fetchSuppliers(this.orderDetail.id);
      await this.fetchInincIdentsDescription(this.incident.id);
      this.isGettingIndicents = true;
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1000px !important;
  }
}

.description {
  background: aliceblue;
  max-height: 671px;
  overflow: auto;
  padding: 10px;
}
.table-bordered td {
  word-break: break-all;
}

.message-box {
  padding: 6px 10px;
  border-radius: 6px 0 6px 0;
  position: relative;
  background: rgba(100, 170, 0, 0.1);
  border: 2px solid rgba(100, 170, 0, 0.1);
  color: #6c6c6c;
  font-size: 12px;
}

.message-box:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid rgba(100, 170, 0, 0.2);
  border-right: none;
  bottom: -22px;
  right: 10px;
}

.message-partner {
  background: rgba(0, 114, 135, 0.1);
  border: 2px solid rgba(0, 114, 135, 0.1);
  align-self: flex-start;
}

.message-partner:after {
  right: auto;
  bottom: auto;
  top: -22px;
  left: 9px;
  border: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 114, 135, 0.2);
  border-left: none;
}

.message-box-holder {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.message-sender {
  font-size: 12px;
  margin: 0 0 15px;
  color: #30649c;
  align-self: flex-start;
}

span.titleDescription {
  font-size: 11px;
  display: block;
  text-align: end;
  margin: 0 0 10px 0;
}
.message-box img {
  object-fit: cover;
  width: 130px;
}
.pdfIcon {
  font-size: 15px;
}
.pdfIcon span {
  font-size: 9px;
  font-weight: 600;
  word-break: break-all;
  width: 161px;
  display: inline-block;
}

span.titleDescriptionFooter {
  display: inline-block;
  width: 50%;
}

span.titleDescriptionFooter.align-items-right {
  text-align: right;
  float: right;
}

span.titleDescriptionFooter.align-items-left {
  float: left;
}
span.pdfIconLoad {
  font-size: 17px;
  margin: 0 0 0 0px;
}
</style>
